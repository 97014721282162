import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../../../../components/AxiosInstance';

const initialState = {
  queryDetail: null,
  loading: false,
  error: null,
};

// Async thunk to retrieve batch details from the backend
export const fetchBatchDetails = createAsyncThunk(
  'detailSlice/fetchBatchDetails',
  async (batchId, { rejectWithValue }) => {
    try {
      console.log('BatchID: ', batchId);
      // Sending the batchId as a query parameter in the GET request
      const response = await AxiosInstance.get(`matrixgen/batch/`, {
        params: { batch_id: batchId }  // Passing batch_id as query parameter
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching batch details');
    }
  }
);

const detailSlice = createSlice({
  name: 'detailSlice',
  initialState,

  reducers: {
    setQueryDetail: (state, action) => {
      state.queryDetail = action.payload;
      fetchBatchDetails(state.queryDetail.batch_id)
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBatchDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.queryDetail = action.payload;
      })
      .addCase(fetchBatchDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setQueryDetail } = detailSlice.actions;

export default detailSlice.reducer;
