// services/modelRetrievalService.js
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AxiosInstance from "../components/AxiosInstance";
import { setCurrentModel } from "../utils/redux/uiSlice";

export function useModelRetrievalService() {
  const dispatch = useDispatch();
  const { currentSolution, currentModel, currentTeam } = useSelector((state) => state.ui);

  const [modelsList, setModelsList] = useState([]);

  useEffect(() => {
    console.log('currentSolution:', currentSolution);

    const fetchData = async () => {
      if (!currentSolution) return;
      try {
        const response = await AxiosInstance.get(`/${currentSolution.toLowerCase()}/models/`, {
          params: { team_name: currentTeam.name }
        });
        console.log('Response data:', response.data);

        const fetchedModels = response.data;

        // Sort models by created_at date
        const sortedModels = fetchedModels.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setModelsList(sortedModels);
        console.log('Sorted models:', sortedModels);
      } catch (error) {
        console.error('Error fetching models data:', error);
      }
    };

    fetchData();
  }, [currentSolution]);

  useEffect(() => {
    if (modelsList.length > 0) {
      dispatch(setCurrentModel(modelsList[0]));
    }
  }, [modelsList, dispatch]);

  console.log('Current Model: ', currentModel);
  return modelsList;
}