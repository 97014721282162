import { configureStore } from '@reduxjs/toolkit';

import uiReducer from './uiSlice';
import userReducer from './userSlice'

import multiFileReducer from '../../pages/application/dashboard/MatrixGen/Multi-File/multiFileSlice'
import singleQueryReducer from '../../pages/application/dashboard/MatrixGen/single/singleQuerySlice'
import detailReducer from '../../pages/application/dashboard/MatrixGen/Details/detailSlice'

const store = configureStore({
  reducer: {
    ui: uiReducer,
    userInfo: userReducer,

    multiFile: multiFileReducer,
    singleQuery: singleQueryReducer,
    detailSlice: detailReducer

  },
});

export default store;