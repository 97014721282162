// ModelMenu.js
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModel } from '../../../../utils/redux/uiSlice';
import AxiosInstance from "../../../../components/AxiosInstance";

import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

export default function ModelMenu({ disabled }) {
  const dispatch = useDispatch();
  const { currentSolution, currentModel, currentTeam } = useSelector((state) => state.ui);

  const [modelsList, setModelsList] = useState([]);

  useEffect(() => {
    console.log('currentSolution:', currentSolution);
    console.log('currentTeam:', currentTeam);

    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(`/${currentSolution.toLowerCase()}/models/`, {
          params: { team_name: currentTeam.name }
        });
        console.log('Response data:', response.data);

        const fetchedModels = response.data;

        // Sort models by created_at date
        const sortedModels = fetchedModels.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setModelsList(sortedModels);
        console.log('Sorted models:', sortedModels);
      } catch (error) {
        console.error('Error fetching models data:', error);
      }
    };

    if (currentSolution && currentTeam) {
      fetchData();
    }
  }, [currentSolution, currentTeam]);

  const handleModelChange = (model) => {
    dispatch(setCurrentModel(model));
  };

  console.log('Current Model: ', currentModel)

  return (
    <div>
      <Listbox value={currentModel} onChange={handleModelChange} disabled={disabled}>
        <div className="flex flex-col space-y-2">
          <Listbox.Label className="text-sm font-medium leading-6 text-custom-white">Select Model*</Listbox.Label>
          <div className="relative">
            <Listbox.Button
              className={`relative w-full bg-white/5 text-custom-white font-medium rounded-md border-0 pl-1.5 py-1.5 text-left shadow-sm ring-1 ring-inset ring-white/10 focus:outline-none sm:text-sm sm:leading-6
              ${disabled ? 'cursor-not-allowed text-gray-500' : 'cursor-default focus:ring-2 focus:ring-accent-hover'}`}
            >
              <span className="block truncate ml-1">
                {currentModel ? currentModel.name : disabled ? 'Model selection disabled in format-only mode' : 'No Models Available'}
              </span>
              {!disabled && (
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                </span>
              )}
            </Listbox.Button>

            {/* Render options only if dropdown is not disabled */}
            {!disabled && currentModel && (
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {modelsList.map((model) => (
                  <Listbox.Option
                    key={model.id}
                    value={model}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-8 pr-4 ${active ? 'bg-gray-800 text-white' : 'text-custom-white'}`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                          {model.name}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-accent">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}

            {/* Professional message when disabled */}
            {disabled && (
              <p className="mt-2 text-sm text-red-500">
                Model selection is disabled when "Format Only" is enabled.
              </p>
            )}
          </div>
        </div>
      </Listbox>
    </div>
  );
}
